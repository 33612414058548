import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { DefaultBody, DefaultPage, HeaderWithText, PaperBody } from '../DefaultComponents';
import { flattenedLayout } from '../../config/navigation';
import { RequestButton, TimeUnitSelect, ProgressBar, DateRangePicker } from './components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getPercentColor, timeUnitConvert } from '../../functions/misc';
import { useDoohForecast } from '../../functions/hooks/forecast/useDoohForecast';

export const Dooh = props => {
    const info = flattenedLayout.doohForecast;

    const [settings, setSettings] = useState({
        type: '2',
        dates: null
    });

    const [request, setRequest] = useState(null);

    const [data, loading, resetData] = useDoohForecast(request);
    const requestForecast = () => setRequest({ settings });

    const handleSettingsChange = (k, v) => {
        setSettings({ ...settings, [k]: v });
        resetData();
    };

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
            <DefaultBody>
                <Grid item xs={6} sm={3} md={4}>
                <DateRangePicker dates={settings.dates} setDates={value => handleSettingsChange('dates', value)} />
                </Grid>
                <Grid item xs={6} sm={3} md={4}>
                    <TimeUnitSelect value={settings.type} setValue={value => handleSettingsChange('type', value)} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <RequestButton loading={loading} click={requestForecast} />
                </Grid>
            </DefaultBody>
            {data && <DoohTable data={data} type={settings?.type}/>}
        </DefaultPage>
    );
};

export const DoohTable = ({ data, type })=> {
    const [page, setPage] = useState(1);

    return (
        <PaperBody>
            <Grid container>
                <Grid item container direction='column' xs>
                    <Grid item container sx={{ height: 55 }}>
                        <Grid item>
                            <Typography variant='h6' sx={{ lineHeight: 1.2 }}>
                                Ledigt lager
                            </Typography>
                        </Grid>
                    </Grid>
                    {data?.occupancyData?.map(entry => {
                        return (
                            <Grid item container key={entry.name} sx={{ height: 55 }} alignItems='center'>
                                <Grid item>
                                    <Typography maxWidth='180px' title={entry.name} noWrap>
                                        {entry.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                {data?.columns?.map((timePeriod, i) => {
                    return (
                        <Grid item container direction='column' xs sx={{ display: { xs: i + 1 === page ? 'flex' : 'none', md: 'flex' } }} key={timePeriod}>
                            <Grid item container sx={{ height: 55 }} justifyContent='center' alignItems='center'>
                                <Grid item xs={2} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        onClick={() => {
                                            if (page > 1) setPage(page - 1);
                                        }}
                                        disabled={page === 1}
                                    >
                                        <ArrowBackIosNewIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={8} md={12} sx={{ textAlign: 'center' }}>
                                    <Typography>{timeUnitConvert(timePeriod, type)}</Typography>
                                </Grid>
                                <Grid item container xs={2} justifyContent='flex-end' sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        onClick={() => {
                                            if (page <= data?.columns?.length) setPage(page + 1);
                                        }}
                                        disabled={page === data?.columns?.length}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            {data?.occupancyData?.map(entry => {
                                const percentage = 100 - entry?.occupancyItems?.[i]?.percentage ?? 0;
                                return (
                                    <Grid item key={entry.id} sx={{ height: 55 }}>
                                        <ProgressBar
                                            value={percentage}
                                            color={getPercentColor(percentage)}
                                            tooltipText={<Typography variant='caption'>{percentage.toFixed(2)}%</Typography>}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </PaperBody>
    );
};
