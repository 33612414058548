import { useEffect, useState } from 'react';
import { getBackendSubDomain } from '../../subDomain';
import { getSitesPlacementsFilter } from '../../placements';
import { formatAudienceTargeting, formatCustomTargeting, formatDate, formatFrequencyCap } from '../../misc';

export const useStandardForecast = request => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const _fetch = async (placements) => {
        if (!placements.length) return null;
        try {
            const payload = {
                start_date: formatDate(request.settings.dates?.from),
                end_date: formatDate(request.settings.dates?.to),
                placements: placements,
                custom_targeting: formatCustomTargeting(request.settings.customTargeting),
                audience_targeting: formatAudienceTargeting(request.settings.audienceTargeting),
                frequency: formatFrequencyCap(request.settings.frequencyCap)
            }

            const response = await fetch(`${getBackendSubDomain('krv')}/gam/forecast/standard`, {
                headers: { 'content-type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(payload)
            });

            const res = await response.json();

            return res;
        } catch (err) {
            console.warn(err);
            return null;
        }
    };

    const resetData = () => setData(null);

    const loadData = async () => {
        setLoading(true);

        const placements = getSitesPlacementsFilter(request.settings.sites, [request.settings.placement], true);
        const resReg = await _fetch(placements);

        setData(resReg)
        setLoading(false);
    };

    useEffect(() => {
        //Check ok values
        if (request) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    return [data, loading, resetData];
};