import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { BreakdownTable } from './BreakdownTable';
import { DefaultBody, DefaultPage, HeaderWithText } from '../DefaultComponents';
import { flattenedLayout } from '../../config/navigation';
import { ArrowExpander, CountSelect, SitePlacementAuto, RequestButton, TimeUnitSelect, CustomTargeting, AudienceTargeting } from './components';
import { useTimeOverviewForecast } from '../../functions/hooks/forecast/useTimeOverviewForecast';
import { useAudiences, useKeyValues } from '../../functions/hooks/forecast/useTargeting';

export const TimeOverview = ({ forecastType }) => {
    const info = flattenedLayout[`${forecastType}Forecast`]

    const [settings, setSettings] = useState({
        placement: null,
        timeQuantity: 5,
        timeUnit: 'weeks',
        customTargeting: { key: null, values: [] },
        audienceTargeting: [],
        forecastType: forecastType
    });

    const [request, setRequest] = useState(null);
    const [ctOptions, kvLoading] = useKeyValues();
    const [audiences, audienceLoading] = useAudiences();
    const [data, loading, resetData] = useTimeOverviewForecast(request);
    const requestForecast = () => setRequest({ settings });

    const handleSettingsChange = (k, v) => {
        setSettings({ ...settings, [k]: v });
        resetData();
    };

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
            <DefaultBody>
                <Grid item xs={12} sm={6} md={4}>
                    <SitePlacementAuto setValue={value => handleSettingsChange('placement', value)} optionType={forecastType} />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <CountSelect value={settings.timeQuantity} setValue={value => handleSettingsChange('timeQuantity', value)} />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TimeUnitSelect value={settings.timeUnit} setValue={value => handleSettingsChange('timeUnit', value)} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <RequestButton disabled={!settings.placement} loading={loading} click={requestForecast} />
                </Grid>
                <ArrowExpander tooltipTitle="styrningsalternativ">
                    <CustomTargeting options={ctOptions} data={settings.customTargeting} setData={value => handleSettingsChange('customTargeting', value)} />
                    <AudienceTargeting options={audiences} data={settings.audienceTargeting} setData={value => handleSettingsChange('audienceTargeting', value)} />
                </ArrowExpander>
            </DefaultBody>
            {data && <BreakdownTable data={data} request={request} dateType={settings.timeUnit} breakdownType={forecastType === "site" ? "placement" : "site"} />}
        </DefaultPage>
    );
};
